import React from 'react';
import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import { PracticeTypes } from '@aider/constants-library';
import { observer } from 'mobx-react';
import { Dropdown, Tooltip } from 'antd';
import type { MenuProps } from 'antd';
import ButtonSecondary from '../../customAntD/ButtonSecondary';
import { useStore } from '../../../stores/Store';
import GenAI from '../../icons/GenAI';
import LLMAssistantModal from '../../modals/LLMAssistantModal';
import { PromptType } from '../../../models/interfaces/components';

const TextActions = ({
  block,
  className
}: {
  block: PracticeTypes.ReportBlock,
  className: string
}) => {
  const { reportTemplateStore } = useStore();
  const isEditing = reportTemplateStore.editBlock === block.id;

  const modalData = [
    {
      subtitle: 'Rewrite',
      text: 'Rewrite',
      type: 'rewrite'
    },
    {
      subtitle: 'Executive Summary',
      text: 'Executive Summary',
      type: 'summary'
    },
    {
      subtitle: 'Action Points',
      text: 'Action Points',
      type: 'action'
    },
    {
      subtitle: 'Custom detail & data',
      text: 'Custom detail & data',
      type: 'custom'
    }
  ];

  const items: MenuProps['items'] = [
    {
      key: '1',
      type: 'group',
      label: 'Select AI assistant...',
      children: modalData.map(({ text, type }) => ({
        key: text,
        label: (
          <a
            onClick={() => {
              LLMAssistantModal(
                {
                  block,
                  type: type as PromptType,
                  reportTemplateStore,
                }
              );
            }}
          >
            { text }
          </a>
        )
      }))
    }
  ];

  return (
    <aside className={className}>
      {!isEditing && (
        <>
          <Dropdown
            placement='bottomRight'
            className='genai-button__dropdown'
            overlayClassName='genai-button__dropdown'
            menu={{ items }}
            arrow
          >
            <ButtonSecondary
              className='borderless'
              icon={<GenAI />}
              mixpanelEvent='Report Editor - Gen AI'
            />
          </Dropdown>
          <Tooltip
            title='Manual Edit'
            placement='bottom'
          >
            <ButtonSecondary
              className='borderless'
              icon={<EditOutlined />}
              mixpanelEvent='Report Editor - Edit Text'
              mixpanelProperties={{ editBy: 'Action Button' }}
              onClick={() => { reportTemplateStore.editBlock = block.id; }}
            />
          </Tooltip>
        </>
      )}
      {isEditing && (
        <>
          <ButtonSecondary
            className='borderless'
            icon={<CheckCircleOutlined />}
            mixpanelEvent='Report Editor - Save Text'
            onClick={() => {
              reportTemplateStore.editBlock = null;
            }}
          />
        </>
      )}
    </aside>
  );
};

export default observer(TextActions);
