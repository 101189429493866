import { PracticeTypes, ReportEnums, ApiConstants, ReportConstants, PeriodDetailEnums } from '@aider/constants-library';
import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { Document as DocXDoc, SectionType, Paragraph, HeadingLevel, Packer, ImageRun, Footer, TextRun, AlignmentType, PageNumber } from 'docx';
import fileDownload from 'js-file-download';
import { convertFromRaw, convertToRaw, Modifier, EditorState, ContentState, Entity } from 'draft-js';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import Format, { DateFormats, ValueTypes } from '@aider/aider-formatting-library';
import { getFinancialYear, getQuarterByDate, lookupGstDueDate, PeriodTypes } from '@aider/aider-period-library';
import { DateTime } from 'luxon';
import draftToHtml from 'draftjs-to-html';
import { DELETE, GET, POST, PUT } from '../lib/requests';
import type { RootStore } from './Store';
import handleError from '../lib/errorHandler';
import Notification from '../components/Notification';
import { calculateInvoiceAverage, calculatePercentageAndRelation, determineValueValidity, generateDocXTable, generateStyledChildren } from '../lib/storeUtils';
import { DOCX_CONFIG } from '../models/constants/stores';
import { trackMixpanelEvent } from '../lib/mixpanel';
import { AntDesignTreeData } from '../models/interfaces/antDesignElements';
import { EditorSuggestionItem, RawReportEditorVariable } from '../models/interfaces/components';
import { InsightsOrder } from '../entities/types';
import { InsightTab } from './v1/pageStore';
import { sortReportInsightTree } from '../lib/componentHelpers/reportHelpers';
import { PeriodMap } from '../models/enums/components';

const variablePattern = /#{[^}]+}/g;

export default class ReportTemplateStore {
  rootStore: RootStore;

  performanceReportTemplates: Map<string, PracticeTypes.ReportPage> = new Map();

  selectedPerformanceReportTemplate: string = ReportEnums.ReportPredefinedTemplates.default;

  editedPerformanceReportTemplate: PracticeTypes.ReportPage;

  selectedPeriodData: {
    start: string;
    end: string;
    name: string;
    granularity?: string;
    monthDuration?: number;
  };

  blockInsights = {};

  editBlock: string;

  fetchingVariables: boolean = false;

  variableFetchTimeout: any;

  templateData: RawReportEditorVariable = new Map();

  get reportFileName() {
    return `${this.selectedPerformanceReport.templateName} - ${this.selectedPeriodData.name} - ${this.rootStore.businessesStore.selectedBusiness.name}`;
  }

  formatTemplateVariable(key: string, valueParam: any): string {
    // Added check for nil value here as some values were undefined and causing the every check in text component to fail
    const value = determineValueValidity(valueParam);

    if (value === 'N/A') {
      return value;
    }

    const format = new Format(this.rootStore.businessesStore.selectedBusiness.currencyCode, this.rootStore.businessesStore.selectedBusiness.countryCode);
    const formatType = ReportEnums.ReportVariables?.[key] || ReportEnums.VariableFormattingTypes.TEXT;

    switch (formatType) {
      case (ReportEnums.VariableFormattingTypes.CURRENCY):
        return format.formatValue({ value, format: ValueTypes.currency });
      case (ReportEnums.VariableFormattingTypes.PERCENTAGE):
        return format.formatValue({ value, format: ValueTypes.percentage });
      case (ReportEnums.VariableFormattingTypes.DATE):
        return format.formatValue({ value, format: ValueTypes.allShortDate });
      case (ReportEnums.VariableFormattingTypes.NUMBER):
        return Math.round(value).toLocaleString();
      case (ReportEnums.VariableFormattingTypes.TEXT):
        return String(value);
      case (ReportEnums.VariableFormattingTypes.IMAGE):
      default:
        return value;
    }
  }

  get filteredInsightGraphData() {
    const usPractice = this.rootStore.practiceStore.isUSPractice;
    const filteredInsightsOrder = InsightsOrder?.filter((insight) => !usPractice || !['reconciliation', 'gst'].includes(insight)) || [];
    const graphInsightData = this.rootStore.insightStore?.insightData?.filter((insight) => filteredInsightsOrder.includes(insight.insightKey));
    // eslint-disable-next-line array-callback-return, consistent-return
    const filteredInsightData = graphInsightData.reduce((acc, insight) => {
      if (insight.categoryData?.categoryId === InsightTab.profitability) {
        if (insight.periods.length
          && this.selectedPeriodData.granularity === PeriodTypes.MONTHLY
        ) {
          const insightData = insight.periods.find((p) => p?.periodData?.name === this.selectedPeriodData.name);
          if (insightData) acc.push(insightData);
        }
        if (insight.quarters.length && this.selectedPeriodData.granularity === PeriodTypes.QUARTERLY) {
          const insightData = insight.quarters?.find(
            (q) => q?.periodData?.name === this.selectedPeriodData.name
          );
          if (insightData) acc.push(insightData);
        }
      } else {
        const nonProfitabilityArray : string[] = Object.values(ReportEnums.VariableInsightMapping).reduce((npa: string[], key) => {
          if (key && npa.indexOf(key as string) === -1) npa.push(key as string);
          return npa;
        }, []);

        if (nonProfitabilityArray.includes(insight.insightKey) && !insight.missing) {
          const selectedGraph = insight;
          if (selectedGraph) acc.push(selectedGraph);
        }
      }
      return acc;
    }, []);
    return filteredInsightData;
  }

  get templateVariables(): AntDesignTreeData[] {
    const templateVariableCategories = ReportConstants.ReportVariableStructure;

    const vars = Object.keys(templateVariableCategories).sort().map((key) => {
      const templateCategories = templateVariableCategories?.[key];
      const arrayCategories = Array.isArray(templateCategories) ? templateCategories : Object.keys(templateCategories);
      return {
        title: this.rootStore.localeStore.translation(`report-template-editor.variables.${key}`),
        value: key,
        key,
        selectable: false,
        children: arrayCategories?.map((insightKey: string) => {
          const child = templateVariableCategories[key][insightKey];
          const title = this.rootStore.localeStore.translation(`report-template-editor.variables.${insightKey}`);
          if (child && typeof child !== 'string') {
            return {
              title,
              value: `${key}_insightCategory_${insightKey}`,
              key: `${key}_insightCategory_${insightKey}`,
              selectable: false,
              children: child?.map((subChild) => {
                let subChildTitle: string;
                const childSplit = subChild.split('_');

                if (childSplit.length > 1) {
                  const comparisonPeriods = childSplit[1];
                  const variable = childSplit[childSplit.length - 1];

                  const periodTranslation = this.rootStore.localeStore.translation(`report-template-editor.variables.${comparisonPeriods}`);
                  const variableTranslation = this.rootStore.localeStore.translation(`report-template-editor.variables.${variable}`);

                  subChildTitle = childSplit.length === 3
                    ? `${periodTranslation} - ${variableTranslation}`
                    : `${variableTranslation}`;
                } else {
                  subChildTitle = this.rootStore.localeStore.translation(`report-template-editor.variables.${subChild}`);
                }

                return {
                  title: subChildTitle,
                  value: `{${subChild}}`,
                  key: `{${subChild}}`,
                };
              })
            };
          }

          return {
            title,
            value: `{${child || insightKey}}`,
            key: `{${child || insightKey}}`,
          };
        }),
      };
    });
    return vars;
  }

  updatedReportContent: string;

  conversationHistory: any[] = [];

  /**
    * If this property is set, the text will be injected into the currently active
    * WYSIWYG editor at the current cursor position
    */
  inject: string = null;

  injectEntity: { type: string, entityKey: string } = null;

  selectedInsightDataPoints: string[] = null;

  setEditBlock(block: PracticeTypes.ReportBlock) {
    this.editBlock = block.id;
  }

  setEditBlockId(blockId: string) {
    this.editBlock = blockId;
  }

  clearEditBlock() {
    this.editBlock = null;
  }

  get reportTemplateSelectionList() {
    const reportTemplateSelectionList = _.sortBy(Array.from(this.performanceReportTemplates.values()), ['templateName'])
      .map((reportTemplate) => ({
        value: reportTemplate.templateId,
        label: `${reportTemplate.templateName}${reportTemplate.templateId === 'aiderDefault' ? ' (Aider Default)' : ''}`,
        key: reportTemplate.templateId,
      }));
    return reportTemplateSelectionList;
  }

  get sortedPerformanceReportTemplates() {
    return Array.from(this.performanceReportTemplates.values()).sort((a, b) => {
      if (a.templateName < b.templateName) {
        return -1;
      }
      if (a.templateName > b.templateName) {
        return 1;
      }
      return 0;
    });
  }

  get formattedTemplateVariables() {
    const mutatedData = { ...this.templateData.get(this.rootStore.businessesStore.selectedBusinessId)?.get(this.selectedPeriodData?.name) };
    const { practice } = this.rootStore.practiceStore;

    ReportConstants.ReportVariableStructure.practice.forEach((variable) => {
      const storeKey = ReportEnums.VariableKeyMapping?.[variable] || variable;
      switch (variable) {
        case 'clientName':
          mutatedData[variable] = this.rootStore.businessesStore.selectedBusiness?.name;
          break;
        case 'clientIndustry':
          mutatedData[variable] = this.rootStore.businessesStore.selectedBusiness?.lineOfBusiness;
          break;
        case 'clientCountry':
          mutatedData[variable] = this.rootStore.businessesStore.selectedBusinessCountryName;
          break;
        default:
          mutatedData[variable] = practice?.[storeKey] || 'Firm data not available';
      }
    });

    Object.keys(mutatedData).forEach((key) => {
      mutatedData[key] = this.formatTemplateVariable(key, mutatedData[key]);
    });

    return mutatedData;
  }

  get fallbackFormattedVariables() {
    return { ...this.fallbackVariableState, ...this.formattedTemplateVariables };
  }

  get selectedPerformanceReport() {
    const selectedPerformanceReport = this.editedPerformanceReportTemplate || this.performanceReportTemplates.get(this.selectedPerformanceReportTemplate);
    const sortedBlocks = _.sortBy(selectedPerformanceReport?.blocks, ['position']);
    return { ...selectedPerformanceReport, blocks: sortedBlocks };
  }

  get selectedPerformanceReportPageCount() {
    return this.selectedPerformanceReport?.blocks?.filter((block) => block.type === 'page').length || 0;
  }

  get selectedPerformanceReportBlocks() {
    return _.sortBy(this.selectedPerformanceReport.blocks, ['position']) || [];
  }

  get isDefaultTemplateSelected() {
    return this.selectedPerformanceReportTemplate === ReportEnums.ReportPredefinedTemplates.default;
  }

  get isTemplateEdited() {
    return !!this.editedPerformanceReportTemplate
      && !_.isEqual(this.editedPerformanceReportTemplate, this.performanceReportTemplates.get(this.selectedPerformanceReportTemplate));
  }

  /**
   * Returns the template variables in a sorted array
   * for use in the WYSIWYG editor variable header dropown
   * @returns The sorted array of template variables
   */
  get sortedTemplateVariables(): AntDesignTreeData[] {
    if (!this.templateVariables) {
      return [];
    }
    return sortReportInsightTree(this.templateVariables);
  }

  /**
   * Returns a flat array of all template variables and their children
   * For use in the WYSIWYG editor variable # suggest dropown
   * @returns The flat array of template variables
   */
  get flatSortedTemplateVariables(): EditorSuggestionItem[] {
    if (!this.templateVariables) {
      return [];
    }

    return this.templateVariables
      ?.flatMap((variable) => {
        if (!variable.children) {
          return { text: variable.title, value: variable.value };
        }
        return variable.children.flatMap((child) => {
          if (!child.children) {
            return { text: child.title, value: child.value };
          }
          return child.children.flatMap((subChild) => ({
            text: subChild.value.split('_').map((part) => this.rootStore.localeStore.translation(`report-template-editor.variables.${part.replace('{', '').replace('}', '')}`)).join(' - '),
            value: subChild.value
          }));
        });
      })
      ?.sort((a, b) => a.text?.localeCompare(b?.text));
  }

  get fallbackVariableState() {
    const fallbackVariableState = {};

    // return fallbackVariableState;
    // Creates fallback state for all variables from ENUM definition
    Object.keys(ReportEnums.ReportVariables).forEach((variable) => {
      const variableName = variable.split('_').map((part) => (this.rootStore.localeStore.translation(`report-template-editor.variables.${part}`))).join(' - ');
      fallbackVariableState[variable] = `${variableName} data not available`;
    });

    return fallbackVariableState;
  }

  updateBlockInsight(blockId: string, insight: string) {
    this.blockInsights[blockId] = insight;
  }

  /**
   * Prepares the selected report template for editing
   * by creating a deep clone of the selected template and sorting the blocks by position
   * to ensure they are in the correct order
   */
  prepEditReportTemplate() {
    const templateClone = _.cloneDeep(this.performanceReportTemplates.get(this.selectedPerformanceReportTemplate));
    const sortedBlocks = _.sortBy(templateClone?.blocks, ['position']);

    if (templateClone) {
      templateClone.blocks = sortedBlocks;
    }

    this.editedPerformanceReportTemplate = templateClone;
  }

  /**
   * Sets the selected report template block to the edited content
   * @param editedContent - The edited content to set
   * @param blockId - The block id to set the content for
   */
  editReportTemplate(editedContent: PracticeTypes.ReportBlock['content'], blockId: PracticeTypes.ReportBlock['id']) {
    const inx = this.editedPerformanceReportTemplate.blocks.findIndex((block) => block.id === blockId);
    this.editedPerformanceReportTemplate.blocks[inx].content = editedContent;
  }

  insertChartPointer(pointer: string, selectedDataPoints: string[]) {
    const inx = this.editedPerformanceReportTemplate.blocks.findIndex((block) => block.id === this.editBlock);
    this.editedPerformanceReportTemplate.blocks[inx].content = convertToRaw(stateFromMarkdown(pointer));
    this.selectedInsightDataPoints = selectedDataPoints;
  }

  insertVariable(variable: string) {
    trackMixpanelEvent({ description: 'Report Editor - Insert Variable', properties: { variable }, rootStore: this.rootStore });
    this.injectEntity = { type: 'MENTION', entityKey: `#${variable}` };
  }

  saveReportSettings(blockId: PracticeTypes.ReportBlock['id'], settings: PracticeTypes.ReportSettingsObject) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }
    if (!this.editedPerformanceReportTemplate.settings) {
      this.editedPerformanceReportTemplate.settings = {};
    }

    if (!this.editedPerformanceReportTemplate.settings[blockId]) {
      this.editedPerformanceReportTemplate.settings[blockId] = {};
    }

    Object.keys(settings).forEach((key) => {
      this.editedPerformanceReportTemplate.settings[blockId][key] = settings[key];
    });
  }

  removeReportSettings(blockSetting: string, blockId: PracticeTypes.ReportBlock['id']) {
    const currentBlockSettings = this.editedPerformanceReportTemplate.settings[blockId];

    if (currentBlockSettings) {
      delete currentBlockSettings[blockSetting];
    }

    this.editedPerformanceReportTemplate.settings[blockId] = currentBlockSettings;
  }

  // eslint-disable-next-line class-methods-use-this
  convertTemplateToDraftJS(editorState: EditorState, currentState: ContentState, template: string): ContentState {
    let modifiedEditorState = editorState;
    // Split template into blocks
    const parts = template.split(variablePattern);

    // Extract all variables from the template
    const variables = template.match(variablePattern) || [];
    let variableIndex = 0;
    let modifiedState = currentState;

    // Modify editor state with new content
    parts.forEach((part) => {
      // Add the block content
      modifiedState = Modifier.replaceText(modifiedState, modifiedEditorState.getSelection(), part, null, null);
      modifiedEditorState = EditorState.push(modifiedEditorState, modifiedState, 'insert-fragment');

      if (variableIndex < variables.length) {
        // Add the variable entity
        const entityKey = Entity.create('MENTION', 'IMMUTABLE', variables[variableIndex]);
        modifiedState = Modifier.replaceText(modifiedState, modifiedEditorState.getSelection(), variables[variableIndex], null, entityKey);
        variableIndex += 1;
      }

      modifiedEditorState = EditorState.push(modifiedEditorState, modifiedState, 'insert-fragment');
    });

    return modifiedEditorState;
  }

  /**
    * Adds a trend text block to the end of the currently edited block
    */
  injectTrendText(insightKey: string) {
    let trendTemplate = '';
    if (!insightKey) {
      Notification({ type: 'error', title: 'Failed to inject trend text' });
      return;
    }

    switch (insightKey) {
      case 'revenue':
      case 'directCosts':
      case 'netProfit':
      case 'grossProfit':
      case 'opex':
        trendTemplate = this.rootStore.templateTextStore.getProfitabilityInsightText(insightKey);
        break;
      case 'cashFlow':
        trendTemplate = this.rootStore.templateTextStore.getCashFlowInsightText();
        break;
      case 'cashFlowActual':
        trendTemplate = this.rootStore.templateTextStore.getCashFlowActualInsightText();
        break;
      case 'salesTax':
        trendTemplate = this.rootStore.templateTextStore.getGSTForecast();
        break;
      case 'incomeTax':
        trendTemplate = this.rootStore.templateTextStore.getIncomeTaxInsightText();
        break;
      case 'invoice':
        trendTemplate = this.rootStore.templateTextStore.getInvoiceStatus();
        break;
      default:
        break;
    }

    this.inject = trendTemplate;
  }

  /**
    * Adds a trend text block to the end of the currently edited block
    */
  injectImage(insightKey: string) {
    if (!insightKey) {
      Notification({ type: 'error', title: 'Failed to inject image' });
      return;
    }
    this.injectEntity = { type: 'IMAGE', entityKey: `#${insightKey}` };
  }

  /**
   * Gets the block position and index for the block id
   * @param blockId - The block id to find
   * @returns The block index and position
   */
  getBlockPositionAndIndex(blockId: string): { blockIndex: number, blockPosition: PracticeTypes.ReportBlock['position'] } {
    let blockIndex: number = this.editedPerformanceReportTemplate.blocks.findIndex((block) => block.id === blockId);
    let blockPosition: PracticeTypes.ReportBlock['position'];

    if (blockIndex === -1) {
      blockIndex = this.editedPerformanceReportTemplate.blocks.length;
      blockPosition = (this.editedPerformanceReportTemplate.blocks.slice(-1)[0]?.position || -1) + 1;
    } else {
      blockPosition = this.editedPerformanceReportTemplate.blocks[blockIndex].position;
    }

    return { blockIndex, blockPosition };
  }

  /**
   * Gets the index and position for the next page block after the block id
   * @param blockId - The block id to find the next page block after
   * @returns The next page block index and position
   */
  getNextPagePositionAndIndex(blockId: string): { nextPageInx: number, nextPagePosition: PracticeTypes.ReportBlock['position'] } {
    const templateIndex = this.editedPerformanceReportTemplate.blocks.findIndex((block) => block.id === blockId);
    const searchStartIndex = templateIndex === -1 ? 0 : templateIndex + 1;
    const nextPageInx = this.editedPerformanceReportTemplate.blocks.findIndex((block, index) => index >= searchStartIndex && block.type === 'page');
    const nextPagePosition = nextPageInx !== -1 ? this.editedPerformanceReportTemplate.blocks[nextPageInx].position : this.editedPerformanceReportTemplate.blocks.length;
    return { nextPageInx, nextPagePosition };
  }

  /**
    * Adds a page block to the end of the report template
    */
  addPageToEnd() {
    const lastPosition = this.editedPerformanceReportTemplate.blocks.slice(-1)[0]?.position || -1;
    this.addPageToPosition(
      this.editedPerformanceReportTemplate.blocks.length,
      lastPosition + 1
    );
  }

  /**
    * Adds a page block to the target index
    * @param targetInx - The target index to add the page block to
    * @param position - The position to set the page block to
    */
  addPageToPosition(targetInx: number, position: PracticeTypes.ReportBlock['position']) {
    if (targetInx === -1) {
      this.addPageToEnd();
      return;
    }
    this.editedPerformanceReportTemplate.blocks.splice(targetInx, 0, {
      id: uuid(),
      type: 'page',
      position,
      content: null
    });
    this.reevaluateBlockPositions();
  }

  /**
    * Increments the position of all blocks from the start index to the end of
    * the blocks array
    */
  incrementBlockPositions(startIndex: number) {
    if (startIndex === -1) {
      return;
    }

    for (let i = startIndex; i < this.editedPerformanceReportTemplate.blocks.length; i += 1) {
      this.editedPerformanceReportTemplate.blocks[i].position += 1;
    }
  }

  /**
    * Iterates through all blocks and reevaluates the block positions
    * to cleanup any gaps in the block positions
    * This is useful when deleting blocks
    */
  reevaluateBlockPositions() {
    const placedBlocks = [];
    this.editedPerformanceReportTemplate.blocks.forEach((block, index) => {
      const updatedBlock = { ...block };
      updatedBlock.position = index;
      placedBlocks.push(updatedBlock);
    });
    this.editedPerformanceReportTemplate.blocks = placedBlocks;
  }

  /**
    * Adds a page block before the target block id
    * @param blockId - The block id to add the page block before
    */
  addPageBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const { blockIndex, blockPosition } = this.getBlockPositionAndIndex(blockId);

    this.incrementBlockPositions(blockIndex);
    this.addPageToPosition(blockIndex, blockPosition);
  }

  getBlock(blockId: PracticeTypes.ReportBlock['id']) {
    return this.selectedPerformanceReportBlocks.find((block) => block.id === blockId);
  }

  /**
    * Deletes a page block and all blocks after it
    * up to the next page block
    * @param blockId - The block id to delete
    */
  deletePageBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const blockIndex = this.editedPerformanceReportTemplate.blocks.findIndex((block) => block.id === blockId);
    if (blockIndex === -1) {
      return;
    }

    let { nextPageInx } = this.getNextPagePositionAndIndex(blockId);
    if (nextPageInx === -1) {
      // Is last page so delete all blocks after this one
      nextPageInx = this.editedPerformanceReportTemplate.blocks.length;
    }
    const deleteBlockCount = nextPageInx - blockIndex;

    this.editedPerformanceReportTemplate.blocks.splice(blockIndex, deleteBlockCount);
    this.reevaluateBlockPositions();
  }

  /**
    * Adds a header block above the selected block
    * @param blockId - The block id to add the text block above
    */
  addHeaderBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const { blockIndex, blockPosition } = this.getBlockPositionAndIndex(blockId);

    this.incrementBlockPositions(blockIndex);

    const newBlock: PracticeTypes.ReportBlock = {
      id: uuid(),
      type: 'header',
      position: blockPosition,
      content: null,
    };

    this.editedPerformanceReportTemplate.blocks.splice(blockIndex, 0, newBlock);

    this.editBlock = newBlock.id;
  }

  /**
    * Adds a text block above the selected block
    * @param blockId - The block id to add the text block above
    */
  addTextBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const { blockIndex, blockPosition } = this.getBlockPositionAndIndex(blockId);

    this.incrementBlockPositions(blockIndex);

    const newBlock: PracticeTypes.ReportBlock = {
      id: uuid(),
      type: 'text',
      position: blockPosition,
      content: null,
    };

    this.editedPerformanceReportTemplate.blocks.splice(blockIndex, 0, newBlock);

    this.editBlock = newBlock.id;

    return newBlock;
  }

  /**
    * Adds a text block above the selected block
    * @param blockId - The block id to add the text block above
    */
  addChartBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const { blockIndex, blockPosition } = this.getBlockPositionAndIndex(blockId);

    this.incrementBlockPositions(blockIndex);

    const newBlock: PracticeTypes.ReportBlock = {
      id: uuid(),
      type: 'chart',
      position: blockPosition,
      content: null,
    };

    this.editedPerformanceReportTemplate.blocks.splice(blockIndex, 0, newBlock);

    this.editBlock = newBlock.id;
  }

  addTableBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const { blockIndex, blockPosition } = this.getBlockPositionAndIndex(blockId);

    this.incrementBlockPositions(blockIndex);

    const newBlock: PracticeTypes.ReportBlock = {
      id: uuid(),
      type: 'table',
      position: blockPosition,
      content: null,
    };

    this.editedPerformanceReportTemplate.blocks.splice(blockIndex, 0, newBlock);

    this.editBlock = newBlock.id;
  }

  deleteActiveBlock() {
    const activeBlock = this.editBlock;
    if (!activeBlock) {
      return;
    }
    this.editBlock = null;
    this.deleteBlock(activeBlock);
  }

  deleteBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const blockIndex = this.editedPerformanceReportTemplate.blocks.findIndex((block) => block.id === blockId);
    if (blockIndex === -1) {
      return;
    }

    this.editedPerformanceReportTemplate.blocks.splice(blockIndex, 1);
    this.reevaluateBlockPositions();
  }

  async fetchPerformanceReportTemplates() {
    const url = `${ApiConstants.apiEndpointsBase.business}/business/${this.rootStore.practiceStore.id}/reportTemplates`;
    GET({
      url,
      rootStore: this.rootStore,
    }).then((response) => {
      if (response) {
        const reportTemplates = new Map<string, PracticeTypes.ReportPage>();
        response.forEach((reportTemplate: PracticeTypes.ReportPage) => {
          const mutatedTemplate = { ...reportTemplate };
          if (!mutatedTemplate.settings) {
            mutatedTemplate.settings = {};
          }
          reportTemplates.set(reportTemplate.templateId, mutatedTemplate);
        });
        this.performanceReportTemplates = reportTemplates;
      }
    });
  }

  async createPerformanceReportTemplate(reportTemplate: PracticeTypes.ReportPage) {
    const url = `${ApiConstants.apiEndpointsBase.business}/business/${this.rootStore.practiceStore.id}/reportTemplates`;
    return POST({
      url,
      rootStore: this.rootStore,
      data: reportTemplate,
    })
      .then((response) => {
        if (response) {
          this.performanceReportTemplates.set(response.templateId, response);
          this.selectedPerformanceReportTemplate = response.templateId;
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async updatePerformanceReportTemplate(reportTemplate: PracticeTypes.ReportPage) {
    const url = `${ApiConstants.apiEndpointsBase.business}/business/${this.rootStore.practiceStore.id}/reportTemplates/${reportTemplate.templateId}`;
    return PUT({
      url,
      rootStore: this.rootStore,
      data: reportTemplate,
    });
  }

  async deletePerformanceReportTemplate(templateId: string) {
    const url = `${ApiConstants.apiEndpointsBase.business}/business/${this.rootStore.practiceStore.id}/reportTemplates/${templateId}`;
    DELETE({
      url,
      rootStore: this.rootStore,
    }).then((response) => {
      if (response) {
        this.performanceReportTemplates.delete(templateId);
      }
    });
  }

  async updateLLMReportContent(prompt: string, material: any, useRag: boolean) {
    this.updatedReportContent = await this.generateLLMContentForReport(prompt, material, useRag);
    return this.updatedReportContent;
  }

  async generateLLMContentForReport(prompt: string, context: any, useRag: boolean) {
    let aggregatedContext = '';

    if (context) {
      aggregatedContext = `${context.originalContent}\n`;
    }

    if (this.conversationHistory.length > 0) {
      aggregatedContext += this.conversationHistory.reduce((acc, item) => (
        `${acc.length > 0 ? `${acc}\n` : ''}${item?.prompt ? `${item.prompt}\n` : ''}${item?.context ? `${item.context}\n` : ''}`
      ), '');
    }

    if (aggregatedContext.length > 0) {
      aggregatedContext += 'You should refer to the above context when answering the following prompt.\n';
    }

    const data = {
      model: 'gpt-4o',
      question: {
        role: 'user',
        content: `${aggregatedContext}${prompt}`,
      },
      useRag,
    };

    return POST({
      url: `${process.env.REACT_APP_LLM_ENDPOINT}api/v1/business/${this.rootStore.businessesStore.selectedBusinessId}/dataChatWithContext`,
      data,
      rootStore: this.rootStore,
    }).then((response) => {
      if (!response?.answer) {
        throw new Error('LLM provided no answer');
      }

      this.conversationHistory.push({ prompt, context: response.answer });

      return response.answer;
    });
  }

  reportedInsights(blockId: string) {
    return (this.editedPerformanceReportTemplate || this.performanceReportTemplates.get(this.selectedPerformanceReportTemplate))?.blocks
      ?.filter(
        ({ id, type, content }) => (
          id !== blockId && type === 'text' && content && !convertFromRaw(content).getPlainText().startsWith('prompt'))
      )
      ?.map(({ content }) => {
        if (content && (content.blocks.length > 1 || content.blocks[0].text !== '')) {
          return _.template(draftToHtml(content), { interpolate: /[#]{([^}]+)}/g })(this.fallbackFormattedVariables);
        }
        return '';
      });
  }

  clearConversationHistory() {
    this.conversationHistory = [];
  }

  breakReportByPages() {
    const pages = [];
    let page = [];
    this.selectedPerformanceReportBlocks.forEach((block, index) => {
      if (block.type === 'page' && index === 0) {
        return;
      }
      if (block.type === 'page') {
        pages.push(page);
        page = [];
      } else {
        page.push(block);
      }
    });
    pages.push(page);
    return pages;
  }

  clearReportContent() {
    this.updatedReportContent = null;
  }

  useReportContent() {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    if (this.updatedReportContent) {
      const newContent = convertToRaw(stateFromMarkdown(this.updatedReportContent));
      this.editReportTemplate(newContent, this.editBlock);
    }
    this.clearReportContent();
  }

  get reportWithClearedPromptBlocksForSaving() {
    const report = _.cloneDeep(this.editedPerformanceReportTemplate || this.performanceReportTemplates.get(this.selectedPerformanceReportTemplate));
    Object.keys(report.settings).forEach((blockId) => {
      const blockSettings = report.settings[blockId];

      if (blockSettings.prompts && blockSettings.prompts.length > 0) {
        const blockRef = report.blocks.find((block) => block.id === blockId);

        if (blockRef) {
          blockRef.content = '';
        }
      }
    });
    return report;
  }

  get graphImages() {
    return this.rootStore.insightStore.insightGraphs;
  }

  storePromptToBlockSettings(prompt: string, blockId: string) {
    const prompts = this.selectedPerformanceReport.settings?.[blockId]?.prompts || [];
    prompts.push(prompt);

    this.saveReportSettings(blockId, { prompts });
  }

  removePromptFromBlockSettings(blockId: string) {
    this.removeReportSettings('prompts', blockId);
  }

  storeOriginalContentToSettings(content: string, blockId: string) {
    const originalContent = this.selectedPerformanceReport.settings?.[blockId]?.originalContent || [];
    originalContent.push(content);

    this.saveReportSettings(blockId, { originalContent });
  }

  removeOriginalContentFromBlockSettings(blockId: string) {
    this.removeReportSettings('originalContent', blockId);
  }

  async updateSelectedReportWithSavedPromptBlocks() {
    return Promise.allSettled((Object.keys(this.selectedPerformanceReport.settings || [])).map(async (blockId) => {
      const blockSettings = this.selectedPerformanceReport.settings[blockId];

      if (blockSettings.prompts && blockSettings.prompts.length > 0) {
        let useRag = true;
        // Determine block type to decide on using RAG for LLM prompt
        const blockType = this.selectedPerformanceReport.blocks.find((block) => block.id === blockId)?.attributes?.type;

        switch (blockType) {
          case 'summary':
          case 'action':
          case 'rewrite':
            useRag = false;
            break;
          case 'custom':
          default:
            useRag = true;
            break;
        }

        // We have a block that has a prompt that needs to be run and applied to the block content
        const llmResponse = await this.generateLLMContentForReport(
          blockSettings.prompts[0],
          { originalContent: blockSettings.originalContent[0] },
          useRag
        );

        this.selectedPerformanceReport.blocks.find((block) => block.id === blockId).content = convertToRaw(stateFromMarkdown(llmResponse));
      }
    }));
  }

  async generateReport() {
    const { graphImages } = this;
    const reportPages = this.breakReportByPages();
    const reportSections = reportPages.map((page) => (
      {
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    text: this.reportFileName,
                    size: 10,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
              new Paragraph({
                children: [
                  new TextRun({
                    children: [
                      'Page ',
                      PageNumber.CURRENT,
                      ' of ',
                      PageNumber.TOTAL_PAGES,
                    ],
                    size: 8,
                  }),
                ],
                alignment: AlignmentType.CENTER,
              }),
            ],
          }),
        },
        properties: {
          type: SectionType.NEXT_PAGE,
        },
        children: page.flatMap((block) => {
          let children;
          let attachment;
          switch (block.type) {
            case 'header':
              return block.content.blocks.flatMap((rawBlock) => {
                children = generateStyledChildren(rawBlock, block?.content?.entityMap);
                return new Paragraph({
                  children,
                  heading: HeadingLevel.TITLE,
                  alignment: rawBlock?.data?.['text-align'] || 'left',
                  spacing: { after: 200 },
                });
              });
            case 'table':
              if (!block.content) {
                return new Paragraph({ text: '' });
              }
              children = convertFromRaw(block.content).getPlainText();
              return generateDocXTable(
                this.rootStore.businessesStore.selectedBusiness,
                this.rootStore.businessesStore?.selectedBusinessFinancialYearEnd,
                this.filteredInsightGraphData.find((insight) => (
                  insight.insightKey === children
                )),
                this.selectedPerformanceReport.settings?.[block.id],
                this.selectedPeriodData?.granularity,
                this.rootStore.localeStore,
              );
            case 'chart':
              if (!block.content) {
                return new Paragraph({ text: '' });
              }
              children = convertFromRaw(block.content).getPlainText();
              if (this.filteredInsightGraphData.findIndex((insight) => insight.insightKey === children) > -1) {
                console.debug('Generating chart for', children, graphImages);
                attachment = graphImages[children];
                return new Paragraph({
                  children: [new ImageRun({
                    data: attachment,
                    transformation: {
                      width: 600,
                      height: 300,
                    },
                  })]
                });
              }
              return new Paragraph({});
            case 'text': {
              if (!block.content) {
                return new Paragraph({ text: '' });
              }
              const blockContent = _.cloneDeep(block.content);
              return blockContent.blocks.flatMap((rawBlock, index, arr) => {
                children = generateStyledChildren(rawBlock, block?.content?.entityMap, this.fallbackFormattedVariables);
                const blockOptions: any = {
                  children,
                  spacing: { after: 200 },
                  alignment: rawBlock?.data?.['text-align'] || 'left',
                };
                switch (rawBlock.type) {
                  case 'title':
                    blockOptions.heading = HeadingLevel.TITLE;
                    break;
                  case 'header-one':
                    blockOptions.heading = HeadingLevel.HEADING_1;
                    break;
                  case 'header-two':
                    blockOptions.heading = HeadingLevel.HEADING_2;
                    break;
                  case 'header-three':
                    blockOptions.heading = HeadingLevel.HEADING_3;
                    break;
                  case 'header-four':
                    blockOptions.heading = HeadingLevel.HEADING_4;
                    break;
                  case 'header-five':
                    blockOptions.heading = HeadingLevel.HEADING_5;
                    break;
                  case 'header-six':
                    blockOptions.heading = HeadingLevel.HEADING_6;
                    break;
                  case 'ordered-list-item':
                    blockOptions.numbering = { reference: 'block-numbering', level: rawBlock.depth };
                    if (arr?.[index + 1]?.type === 'ordered-list-item') {
                      delete blockOptions.spacing;
                    }
                    break;
                  case 'unordered-list-item':
                    blockOptions.bullet = { level: rawBlock.depth };
                    if (arr?.[index + 1]?.type === 'unordered-list-item') {
                      delete blockOptions.spacing;
                    }
                    break;
                  default:
                    break;
                }

                return new Paragraph(blockOptions);
              });
            }
            default:
              return new Paragraph({ text: block?.content || '' });
          }
        })
      }
    ));

    const doc = new DocXDoc({ sections: reportSections, ...DOCX_CONFIG });

    return Packer.toBlob(doc)
      .then((blob) => {
        fileDownload(
          blob,
          `${this.reportFileName}.docx`,
        );
      })
      .then(() => {
        Notification({ type: 'success', title: 'Report generated successfully' });
      })
      .catch((error) => {
        handleError(error);
        Notification({ type: 'error', title: 'Failed to generate report' });
      });
  }

  dataFetchPeriods() {
    let months = 1;
    const { businessesStore } = this.rootStore;
    const periods = [];
    const currentPeriod = { ...this.selectedPeriodData };
    const period = { ...this.selectedPeriodData };
    const financialYear = getFinancialYear(
      DateTime.fromISO(currentPeriod.start, { setZone: true }), businessesStore.selectedBusinessFinancialYearEndObject, businessesStore.selectedBusiness.timeZoneId
    );

    switch (period.granularity) {
      case PeriodTypes.YEARLY:
        months = 12;
        break;
      case PeriodTypes.QUARTERLY:
        months = 3;
        break;
      default:
        break;
    }

    // Current period
    currentPeriod.name = 'current';
    currentPeriod.start = DateTime.fromISO(currentPeriod.start, { setZone: true }).toISODate();
    currentPeriod.end = DateTime.fromISO(currentPeriod.end, { setZone: true }).toISODate();
    periods.push(currentPeriod);

    // One period ago same year
    period.start = DateTime.fromISO(currentPeriod.start, { setZone: true }).minus({ months }).toISODate();
    period.end = DateTime.fromISO(currentPeriod.end, { setZone: true }).minus({ months }).toISODate();
    period.name = 'last';
    periods.push({ ...period });

    // Same period last year
    period.start = DateTime.fromISO(currentPeriod.start, { setZone: true }).minus({ years: 1 }).toISODate();
    period.end = DateTime.fromISO(currentPeriod.end, { setZone: true }).minus({ years: 1 }).toISODate();
    period.name = 'lastYear';
    periods.push({ ...period });

    // Financial year to current period
    period.start = financialYear.start.toISODate();
    period.end = DateTime.fromISO(currentPeriod.end, { setZone: true }).toISODate();
    period.name = 'financialYear';
    periods.push({ ...period });

    // Income Tax Period (uses same dates as financial year)
    period.name = 'incomeTax';
    period.monthDuration = 12;
    periods.push({ ...period });
    delete period.monthDuration;

    // One financial year ago to current period
    period.start = financialYear.start.minus({ years: 1 }).toISODate();
    period.end = DateTime.fromISO(currentPeriod.end, { setZone: true }).minus({ years: 1 }).toISODate();
    period.name = 'lastFinancialYear';
    periods.push({ ...period });

    // Two financial year ago to current period
    period.start = financialYear.start.minus({ years: 2 }).toISODate();
    period.end = DateTime.fromISO(currentPeriod.end, { setZone: true }).minus({ years: 2 }).toISODate();
    period.name = 'previousFinancialYear';
    periods.push({ ...period });

    // 12 months ago to last month
    const today = DateTime.utc().setZone(businessesStore.selectedBusiness.timeZoneId);
    const lastMonth = today.set({ day: 1 }).minus({ months: 1 });
    period.start = lastMonth.minus({ years: 1 }).toISODate();
    period.end = lastMonth.endOf('month').toISODate();
    period.name = 'yearAvgInclLastMonth';
    periods.push({ ...period });

    return periods;
  }

  // Gets the period ranges for the selected period plus the active periods
  // for the insights that are not profitability insights
  get periodRanges(): { start: string, end: string, name: string }[] {
    const insightPeriods = this.filteredInsightGraphData.filter((insightData) => (
      insightData?.categoryData?.categoryId !== InsightTab.profitability
    )).map((insightData) => {
      const insightPeriod = { key: insightData.insightKey, ...insightData.periodData };
      return insightPeriod;
    });

    let fixedPeriodRanges = insightPeriods?.reduce((acc, insight) => {
      const key = insight.key === 'gst' ? 'salesTax' : insight.key;
      const start = DateTime.fromISO(insight.start, { zone: this.rootStore.businessesStore.selectedBusiness?.timeZoneId });
      const end = DateTime.fromISO(insight.end, { zone: this.rootStore.businessesStore.selectedBusiness?.timeZoneId });
      const monthDuration = Math.ceil(Math.abs(start.diff(end, 'months').toObject().months));

      if (!acc[key]) {
        acc[key] = {
          start: start.toISODate(),
          end: end.toISODate(),
          monthDuration,
          name: key
        };
      }

      if (insight.key === 'cashFlowActual') {
        acc.cashFlowActualLastPeriod = {
          start: start.minus({ months: monthDuration }).toISODate(),
          end: end.minus({ months: monthDuration }).toISODate(),
          monthDuration,
          name: 'cashFlowActualLastPeriod'
        };
      }

      return acc;
    }, {});

    fixedPeriodRanges = { ...fixedPeriodRanges, ...this.dataFetchPeriods() };

    return Object.values(fixedPeriodRanges);
  }

  async fetchSelectedPeriodVariables() {
    const businessId = this.rootStore.businessesStore.selectedBusinessId;
    const { selectedBusiness } = this.rootStore.businessesStore;

    const periods = this.periodRanges;

    this.fetchingVariables = true;

    if (!this.templateData.has(businessId)) {
      this.templateData.set(businessId, new Map());
    }

    const url = `${ApiConstants.apiEndpointsBase.insights}/businesses/${businessId}/datapoints`;

    clearTimeout(this.variableFetchTimeout);
    this.variableFetchTimeout = setTimeout(() => {
      PUT({
        url,
        data: { periods },
        rootStore: this.rootStore,
      })
        .then((response) => {
          if (response) {
            const templateVariableCategories = ReportConstants.ReportVariableStructure;
            const vars = {};

            Object.keys(templateVariableCategories).sort().forEach((key) => {
              const templateCategories = templateVariableCategories?.[key];
              const arrayCategories = Array.isArray(templateCategories) ? templateCategories : Object.keys(templateCategories);

              arrayCategories.forEach((insightKey: string) => {
                if (ReportConstants.ReportVariableStructure.practice.indexOf(insightKey) === -1) {
                  const child = templateVariableCategories[key][insightKey];

                  child.forEach((subChild) => {
                    let variable; let comparisonPeriods; let insight; let workingPeriod; let periodShift;
                    let calculation: { difference: number, percentage: number | string, relation: string, moreOrLess: string, increaseOrDecrease: string };
                    let incomeData: { prevAmountAvgPeriodNormalised: number, prevCountAvgPeriodNormalised: number };
                    const childSplit = subChild.split('_');

                    switch (childSplit.length) {
                      case 1:
                        [variable] = childSplit;

                        workingPeriod = getFinancialYear(
                          DateTime.fromISO(this.selectedPeriodData.start),
                          this.rootStore.businessesStore.selectedBusinessFinancialYearEndObject,
                          this.rootStore.businessesStore.selectedBusiness.timeZoneId
                        );

                        if ([
                          ReportEnums.ReportPeriodVariables.priorFinancialYearEnd,
                          ReportEnums.ReportPeriodVariables.priorFinancialYearEndYear,
                          ReportEnums.ReportPeriodVariables.twoPriorFinancialYearEnd,
                          ReportEnums.ReportPeriodVariables.twoPriorFinancialYearEndYear,
                          ReportEnums.ReportPeriodVariables.nextPeriodMonth,
                          ReportEnums.ReportPeriodVariables.nextPeriodYear,
                          ReportEnums.ReportPeriodVariables.nextPeriodName,
                        ].indexOf(variable) > -1) {
                          switch (variable) {
                            case ReportEnums.ReportPeriodVariables.twoPriorFinancialYearEnd:
                            case ReportEnums.ReportPeriodVariables.twoPriorFinancialYearEndYear:
                              periodShift = { years: 2 };
                              break;
                            default:
                              periodShift = { years: 1 };
                              break;
                          }

                          switch (variable) {
                            case ReportEnums.ReportPeriodVariables.nextPeriodMonth:
                            case ReportEnums.ReportPeriodVariables.nextPeriodYear:
                            case ReportEnums.ReportPeriodVariables.nextPeriodName:
                              workingPeriod.start = DateTime.fromISO(workingPeriod.start).plus(periodShift).toISODate();
                              workingPeriod.end = DateTime.fromISO(workingPeriod.end).plus(periodShift).toISODate();
                              break;
                            default:
                              workingPeriod.start = DateTime.fromISO(workingPeriod.start).minus(periodShift).toISODate();
                              workingPeriod.end = DateTime.fromISO(workingPeriod.end).minus(periodShift).toISODate();
                          }
                        }

                        switch (variable) {
                          case 'estimatedNetCashFlow':
                            // Estimated net cash flow
                            vars[subChild] = response.cashFlow?.estimatedCashPosition - response.cashFlow?.openingCashPosition;
                            break;
                          case 'estimatedCashPosition':
                            // Estimated cash position
                            vars[subChild] = response.cashFlow?.estimatedCashPosition;
                            break;
                          case 'netCashFlow':
                            // Net cash flow
                            vars[subChild] = response.cashFlowActual?.closingCashPosition - response.cashFlowActual?.openingCashPosition;
                            break;
                          case 'averageInvoiceCount':
                            incomeData = calculateInvoiceAverage(response.yearAvgInclLastMonth, selectedBusiness);
                            vars[subChild] = incomeData.prevCountAvgPeriodNormalised;
                            break;
                          case 'averageMonthlyInvoiceAmount':
                            incomeData = calculateInvoiceAverage(response.yearAvgInclLastMonth, selectedBusiness);
                            vars[subChild] = incomeData.prevAmountAvgPeriodNormalised;
                            break;
                          case ReportEnums.ReportPeriodVariables.financialYearEnd:
                          case ReportEnums.ReportPeriodVariables.priorFinancialYearEnd:
                          case ReportEnums.ReportPeriodVariables.twoPriorFinancialYearEnd:
                            vars[subChild] = this.rootStore.localeStore.formatDate(workingPeriod.end, DateFormats.string);
                            break;
                          case ReportEnums.ReportPeriodVariables.financialYearEndYear:
                          case ReportEnums.ReportPeriodVariables.priorFinancialYearEndYear:
                          case ReportEnums.ReportPeriodVariables.twoPriorFinancialYearEndYear:
                            vars[subChild] = this.rootStore.localeStore.formatDate(workingPeriod.end, DateFormats.fullYear);
                            break;
                          case ReportEnums.ReportPeriodVariables.todaysDate:
                            vars[subChild] = this.rootStore.localeStore.formatDate(DateTime.now().toISODate(), DateFormats.string);
                            break;
                          case ReportEnums.ReportPeriodVariables.currentMonth:
                            vars[subChild] = this.rootStore.localeStore.formatDate(DateTime.now().startOf('month').toISODate(), DateFormats.fullMonth);
                            break;
                          case ReportEnums.ReportPeriodVariables.currentYear:
                            vars[subChild] = this.rootStore.localeStore.formatDate(DateTime.now().startOf('month').toISODate(), DateFormats.fullYear);
                            break;
                          case ReportEnums.ReportPeriodVariables.lastMonth:
                            vars[subChild] = this.rootStore.localeStore.formatDate(DateTime.now().startOf('month').minus({ months: 1 }).toISODate(), DateFormats.fullMonth);
                            break;
                          case ReportEnums.ReportPeriodVariables.lastYear:
                            vars[subChild] = this.rootStore.localeStore.formatDate(DateTime.now().startOf('month').minus({ years: 1 }).toISODate(), DateFormats.fullYear);
                            break;
                          case ReportEnums.ReportPeriodVariables.nextMonth:
                            vars[subChild] = this.rootStore.localeStore.formatDate(DateTime.now().startOf('month').plus({ months: 1 }).toISODate(), DateFormats.fullMonth);
                            break;
                          case ReportEnums.ReportPeriodVariables.nextYear:
                            vars[subChild] = this.rootStore.localeStore.formatDate(DateTime.now().startOf('month').plus({ years: 1 }).toISODate(), DateFormats.fullYear);
                            break;
                          default:
                            vars[subChild] = response.current[variable];
                            break;
                        }
                        break;
                      case 2:
                        [variable, comparisonPeriods] = childSplit;
                        if (variable === 'invoice') {
                          variable = 'invoiceStatus';
                        }

                        // Activate appropriate period to reference for given insight / variable
                        workingPeriod = variable === 'profitability'
                          ? { ...periods.find((period) => period.name === 'current') }
                          : { ...periods.find((period) => period.name === variable) };

                        // If variable is of prior period type, define correct period shift for granularity and update working period
                        if (workingPeriod && [
                          ReportEnums.ReportPeriodVariables.priorPeriodStartDate,
                          ReportEnums.ReportPeriodVariables.priorPeriodStartMonth,
                          ReportEnums.ReportPeriodVariables.priorPeriodStartYear,
                          ReportEnums.ReportPeriodVariables.firstDayOfPriorPeriod,
                          ReportEnums.ReportPeriodVariables.priorPeriodEndDate,
                          ReportEnums.ReportPeriodVariables.priorPeriodEndMonth,
                          ReportEnums.ReportPeriodVariables.priorPeriodEndYear,
                          ReportEnums.ReportPeriodVariables.lastDayOfPriorPeriod,
                          ReportEnums.ReportPeriodVariables.priorPeriodName,
                          ReportEnums.ReportPeriodVariables.nextPeriodMonth,
                          ReportEnums.ReportPeriodVariables.nextPeriodYear,
                          ReportEnums.ReportPeriodVariables.nextPeriodName,
                          ReportEnums.ReportPeriodVariables.samePeriodPriorYearStartDate,
                          ReportEnums.ReportPeriodVariables.samePeriodPriorYearEndDate,
                          ReportEnums.ReportPeriodVariables.samePeriodPriorYearStartYear,
                          ReportEnums.ReportPeriodVariables.samePeriodPriorYearEndYear,
                          ReportEnums.ReportPeriodVariables.samePeriodPriorYearName,
                          ReportEnums.ReportPeriodVariables.twoPriorPeriodStartDate,
                          ReportEnums.ReportPeriodVariables.twoPriorPeriodStartMonth,
                          ReportEnums.ReportPeriodVariables.twoPriorPeriodStartYear,
                          ReportEnums.ReportPeriodVariables.firstDayOfTwoPriorPeriod,
                          ReportEnums.ReportPeriodVariables.twoPriorPeriodEndDate,
                          ReportEnums.ReportPeriodVariables.twoPriorPeriodEndMonth,
                          ReportEnums.ReportPeriodVariables.twoPriorPeriodEndYear,
                          ReportEnums.ReportPeriodVariables.lastDayOfTwoPriorPeriod,
                          ReportEnums.ReportPeriodVariables.twoPriorPeriodName,
                        ].indexOf(comparisonPeriods) !== -1) {
                          if (workingPeriod?.monthDuration) {
                            periodShift = { months: workingPeriod.monthDuration };
                          } else {
                            switch (this.selectedPeriodData.granularity) {
                              case PeriodMap.gstPeriods:
                                periodShift = { months: PeriodDetailEnums.MonthsInTaxPeriod[this.rootStore.businessesStore.selectedBusinessSalesTaxPeriod] || 6 };
                                break;
                              case PeriodMap.quarterlyPeriods:
                                periodShift = { months: 3 };
                                break;
                              default:
                                periodShift = { months: 1 };
                            }
                          }

                          if ([
                            ReportEnums.ReportPeriodVariables.samePeriodPriorYearStartDate,
                            ReportEnums.ReportPeriodVariables.samePeriodPriorYearEndDate,
                            ReportEnums.ReportPeriodVariables.samePeriodPriorYearStartYear,
                            ReportEnums.ReportPeriodVariables.samePeriodPriorYearEndYear,
                            ReportEnums.ReportPeriodVariables.samePeriodPriorYearName
                          ].indexOf(comparisonPeriods) !== -1) {
                            periodShift = { years: 1 };
                          }

                          if ([
                            ReportEnums.ReportPeriodVariables.twoPriorPeriodStartDate,
                            ReportEnums.ReportPeriodVariables.twoPriorPeriodStartMonth,
                            ReportEnums.ReportPeriodVariables.twoPriorPeriodStartYear,
                            ReportEnums.ReportPeriodVariables.firstDayOfTwoPriorPeriod,
                            ReportEnums.ReportPeriodVariables.twoPriorPeriodEndDate,
                            ReportEnums.ReportPeriodVariables.twoPriorPeriodEndMonth,
                            ReportEnums.ReportPeriodVariables.twoPriorPeriodEndYear,
                            ReportEnums.ReportPeriodVariables.lastDayOfTwoPriorPeriod,
                            ReportEnums.ReportPeriodVariables.twoPriorPeriodName,
                          ].indexOf(comparisonPeriods) !== -1) {
                            periodShift.months *= 2;
                          }

                          switch (comparisonPeriods) {
                            case ReportEnums.ReportPeriodVariables.nextPeriodMonth:
                            case ReportEnums.ReportPeriodVariables.nextPeriodYear:
                            case ReportEnums.ReportPeriodVariables.nextPeriodName:
                              workingPeriod.start = DateTime.fromISO(workingPeriod?.start, { setZone: true }).plus(periodShift).startOf('month').toISODate();
                              workingPeriod.end = DateTime.fromISO(workingPeriod?.end, { setZone: true }).plus(periodShift).endOf('month').toISODate();
                              break;
                            default:
                              workingPeriod.start = DateTime.fromISO(workingPeriod?.start, { setZone: true }).minus(periodShift).startOf('month').toISODate();
                              workingPeriod.end = DateTime.fromISO(workingPeriod?.end, { setZone: true }).minus(periodShift).endOf('month').toISODate();
                          }
                        }

                        if (comparisonPeriods === 'fyToCurrentPeriod') {
                          vars[subChild] = response.financialYear[variable];
                        } else if (comparisonPeriods === 'last') {
                          if (variable === 'netCashFlow') {
                            vars[subChild] = response.cashFlowActualLastPeriod?.closingCashPosition - response.cashFlowActualLastPeriod?.openingCashPosition;
                          }

                          // Insight period start date calculations
                        } else if (comparisonPeriods === 'lastFY') {
                          // Only used by incomeTax at the moment
                          vars[subChild] = response.lastFinancialYear[variable];
                        } else if (comparisonPeriods === 'insightName') {
                          vars[subChild] = this.rootStore.localeStore.translation(`report-template-editor.variables.${insightKey}`);
                        } else if (comparisonPeriods === ReportEnums.ReportPeriodVariables.dueDate) {
                          vars[subChild] = this.rootStore.localeStore.formatDate(
                            lookupGstDueDate(
                              DateTime.fromISO(workingPeriod.start),
                              this.rootStore.businessesStore.selectedBusiness?.profile?.taxAgent,
                              this.rootStore.businessesStore.selectedBusiness?.countryCode,
                              this.rootStore.businessesStore?.selectedBusinessSalesTaxPeriod,
                              null,
                              this.rootStore.businessesStore.selectedBusinessFinancialYearEndObject,
                            ).dueDate, DateFormats.string
                          );
                        } else if ([
                          ReportEnums.ReportPeriodVariables.periodStartDate,
                          ReportEnums.ReportPeriodVariables.priorPeriodStartDate,
                          ReportEnums.ReportPeriodVariables.twoPriorPeriodStartDate,
                          ReportEnums.ReportPeriodVariables.samePeriodPriorYearStartDate,
                        ].indexOf(comparisonPeriods) > -1) {
                          vars[subChild] = this.rootStore.localeStore.formatDate(workingPeriod?.start, DateFormats.string);
                        } else if ([
                          ReportEnums.ReportPeriodVariables.periodStartMonth,
                          ReportEnums.ReportPeriodVariables.priorPeriodStartMonth,
                          ReportEnums.ReportPeriodVariables.twoPriorPeriodStartMonth,
                        ].indexOf(comparisonPeriods) > -1) {
                          vars[subChild] = this.rootStore.localeStore.formatDate(workingPeriod?.start, DateFormats.fullMonth);
                        } else if ([
                          ReportEnums.ReportPeriodVariables.periodStartYear,
                          ReportEnums.ReportPeriodVariables.priorPeriodStartYear,
                          ReportEnums.ReportPeriodVariables.twoPriorPeriodStartYear,
                          ReportEnums.ReportPeriodVariables.samePeriodPriorYearStartYear,
                        ].indexOf(comparisonPeriods) > -1) {
                          vars[subChild] = this.rootStore.localeStore.formatDate(workingPeriod?.start, DateFormats.fullYear);
                        } else if ([
                          ReportEnums.ReportPeriodVariables.firstDayOfPeriod,
                          ReportEnums.ReportPeriodVariables.firstDayOfPriorPeriod,
                          ReportEnums.ReportPeriodVariables.firstDayOfTwoPriorPeriod
                        ].indexOf(comparisonPeriods) > -1) {
                          vars[subChild] = this.rootStore.localeStore.formatDate(workingPeriod?.start, DateFormats.dayMonth);
                          // Insight period end date calculations
                        } else if ([
                          ReportEnums.ReportPeriodVariables.periodEndDate,
                          ReportEnums.ReportPeriodVariables.priorPeriodEndDate,
                          ReportEnums.ReportPeriodVariables.twoPriorPeriodEndDate,
                          ReportEnums.ReportPeriodVariables.samePeriodPriorYearEndDate,
                        ].indexOf(comparisonPeriods) > -1) {
                          vars[subChild] = this.rootStore.localeStore.formatDate(workingPeriod?.end, DateFormats.string);
                        } else if ([
                          ReportEnums.ReportPeriodVariables.periodEndMonth,
                          ReportEnums.ReportPeriodVariables.priorPeriodEndMonth,
                          ReportEnums.ReportPeriodVariables.twoPriorPeriodEndMonth,
                          ReportEnums.ReportPeriodVariables.nextPeriodMonth,
                        ].indexOf(comparisonPeriods) > -1) {
                          vars[subChild] = this.rootStore.localeStore.formatDate(workingPeriod?.end, DateFormats.fullMonth);
                        } else if ([
                          ReportEnums.ReportPeriodVariables.periodEndYear,
                          ReportEnums.ReportPeriodVariables.priorPeriodEndYear,
                          ReportEnums.ReportPeriodVariables.twoPriorPeriodEndYear,
                          ReportEnums.ReportPeriodVariables.nextPeriodYear,
                          ReportEnums.ReportPeriodVariables.samePeriodPriorYearEndYear,
                        ].indexOf(comparisonPeriods) > -1) {
                          vars[subChild] = this.rootStore.localeStore.formatDate(workingPeriod?.end, DateFormats.fullYear);
                        } else if ([
                          ReportEnums.ReportPeriodVariables.lastDayOfPeriod,
                          ReportEnums.ReportPeriodVariables.lastDayOfPriorPeriod,
                          ReportEnums.ReportPeriodVariables.lastDayOfTwoPriorPeriod
                        ].indexOf(comparisonPeriods) > -1) {
                          vars[subChild] = this.rootStore.localeStore.formatDate(workingPeriod?.end, DateFormats.dayMonth);
                        } else if ([
                          ReportEnums.ReportPeriodVariables.periodName,
                          ReportEnums.ReportPeriodVariables.priorPeriodName,
                          ReportEnums.ReportPeriodVariables.twoPriorPeriodName,
                          ReportEnums.ReportPeriodVariables.nextPeriodName,
                          ReportEnums.ReportPeriodVariables.samePeriodPriorYearName,
                        ].indexOf(comparisonPeriods) > -1) {
                          const start = this.rootStore.localeStore.formatDate(workingPeriod?.start, DateFormats.shortMonth);
                          const end = this.rootStore.localeStore.formatDate(workingPeriod?.end, DateFormats.shortMonth);
                          const endFmt = this.rootStore.localeStore.formatDate(workingPeriod?.end, DateFormats.monthYear);
                          vars[subChild] = `${start !== end ? `${start} - ` : ''}${endFmt}`;
                        } else if (comparisonPeriods === ReportEnums.ReportPeriodVariables.currentPeriod) {
                          if (this.selectedPeriodData?.granularity === PeriodMap.quarterlyPeriods) {
                            vars[subChild] = `Q${getQuarterByDate(DateTime.fromISO(workingPeriod.end), this.rootStore.businessesStore.selectedBusinessFinancialYearEndObject)}`;
                          } else {
                            vars[subChild] = this.rootStore.localeStore.formatDate(workingPeriod.end, DateFormats.fullMonth);
                          }
                        }
                        break;
                      default:
                        // At this stage the length will always be 3
                        [insight, comparisonPeriods, variable] = childSplit;

                        switch (comparisonPeriods) {
                          case 'currentVsLast':
                            if (insight === 'netCashFlow') {
                              const currentNetCashFlow = response.cashFlowActual?.closingCashPosition - response.cashFlowActual?.openingCashPosition;
                              const lastNetCashFlow = response.cashFlowActualLastPeriod?.closingCashPosition - response.cashFlowActualLastPeriod?.openingCashPosition;
                              calculation = calculatePercentageAndRelation(currentNetCashFlow, lastNetCashFlow);
                              calculation.relation = calculation.increaseOrDecrease;
                              break;
                            } else if (insight === 'endCashPosition') {
                              calculation = calculatePercentageAndRelation(response.cashFlowActual?.closingCashPosition, response.cashFlowActualLastPeriod?.closingCashPosition);
                              calculation.relation = calculation.increaseOrDecrease;
                              break;
                            }
                            calculation = calculatePercentageAndRelation(response.current?.[insight], response.last?.[insight]);
                            break;
                          case 'currentVsLastYear':
                            calculation = calculatePercentageAndRelation(response.current?.[insight], response.lastYear?.[insight]);
                            break;
                          case 'fyToCurrentPeriodVsLastFYToCurrentPeriod':
                            calculation = calculatePercentageAndRelation(response.financialYear?.[insight], response.lastFinancialYear?.[insight]);
                            break;
                          case 'fyToCurrentPeriodVsPreviousFYToCurrentPeriod':
                            calculation = calculatePercentageAndRelation(response.financialYear?.[insight], response.previousFinancialYear?.[insight]);
                            break;
                          case 'completionRate':
                            // eslint-disable-next-line no-case-declarations
                            const combined = Math.abs(response.salesTax?.reconciledLines + response.salesTax?.unreconciledLines);
                            calculation = {
                              difference: response.salesTax?.reconciledLines - response.salesTax?.unreconciledLines,
                              percentage: combined === 0 ? 100 : (response.salesTax?.reconciledLines / combined) * 100,
                              relation: '',
                              moreOrLess: '',
                              increaseOrDecrease: ''
                            };
                            break;
                          case 'currentVsAverage':
                            if (insight === 'invoice') {
                              incomeData = calculateInvoiceAverage(response.yearAvgInclLastMonth, selectedBusiness);
                              calculation = calculatePercentageAndRelation(response.current?.[insight], incomeData.prevCountAvgPeriodNormalised);
                            }
                            break;
                          default:
                            break;
                        }

                        if (variable === 'percentage') {
                          vars[subChild] = calculation.percentage;
                        } else if (variable === 'moreOrLess') {
                          vars[subChild] = calculation.moreOrLess;
                        } else {
                          vars[subChild] = calculation.relation;
                        }

                        break;
                    }
                  });
                }
              });
            });

            this.templateData
              .get(businessId)
              .set(this.selectedPeriodData.name, vars);
          }
        })
        .catch((error) => {
          handleError({ error });
          Notification({ type: 'error', title: 'Failed to fetch variables' });
          this.fetchingVariables = false;
        })
        .finally(() => {
          this.fetchingVariables = false;
        });
    }, 250);
  }

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }
}
